import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { IoIosArrowBack } from "react-icons/io"
import { Link } from "gatsby"

const StyledThanks = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
`

const Greeting = styled.div`
  font-size: 40px;
  line-height: 1.8em;
`

const GoBack = styled.div`
  font-size: 40px;
  line-height: 1.8em;
  display: flex;
  justify-content: center;

  & a {
    display: flex;
    align-items: center;
  }

  & svg {
    font-size: 1.2em;
  }
`

const Thanks = ({ location }: { location: { state: { name: string } } }) => {
  const name = location?.state?.name

  return (
    <StyledThanks>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Greeting>
        {name
          ? `${name}, takk for din henvendelse!`
          : "Takk for din henvendelse!"}
        <GoBack>
          <Link to="/">
            <IoIosArrowBack /> Gå tilbake til nettsiden
          </Link>
        </GoBack>
      </Greeting>
    </StyledThanks>
  )
}

export default Thanks
